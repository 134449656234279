import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'index',
      component: () => import('../views/index.vue')
    },
    {//文章详情页面
      path: '/articleDetails',
      name: 'ArticleDetails',
      component: () => import('../views/ArticleDetails.vue')
    },
    {//报表页面
      path: '/StatementOne',
      name: 'StatementOne',
      component: () => import('../views/StatementOne.vue')
    },
    {//报表页面
      path: '/StatementTwo',
      name: 'StatementTwo',
      component: () => import('../views/StatementTwo.vue')
    }
  ];
  const indexArr= [
    {
      component: () => import("@/views/Home"),
      name: '首页',
      path: '/',
      Icon: require('@/../public/image/icon/shouyeNo.png'),
      activeIcon: require('@/../public/image/icon/shouyeYes.png')
    },
    {
      component: () => import("@/views/About"),
      name: '报表',
      path: 'about',
      Icon: require('@/../public/image/icon/fenxiNo.png'),
      activeIcon: require('@/../public/image/icon/fenxiYes.png')
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  indexArr
})

export default router
