"use strict";
// import {Toast, ImagePreview} from 'vant';
import routerObj from '@/router'
// better-scroll
// import BScroll from 'better-scroll'

(function (window) {
    let u = {};

    u.router = (path, query, pageState, toState) => {
        if (path.indexOf('index_') === -1) {
            window.vm.$store.commit('setTransition', pageState ? 'slide-left' : 'slide-right');
            if (pageState && !path) {
                window.vm.$router.back(-1)
            } else if (toState === 'replace') {
                window.vm.$router.replace({
                    path: '/' + path,
                    query: query || {},
                });
            } else {
                window.vm.$router.push({
                    path: '/' + path,
                    query: query || {},
                });
            }
        } else {
            for (let i in routerObj.indexArr) {
                console.log(routerObj.indexArr[i].path);
                if (routerObj.indexArr[i].path === path) {
                    u.router("index", {
                        index: i
                    }, true)
                }
            }
        }
    };


//动态修改title的值
    // u.routerPush = (key, value) => {
    //     let query = window.vm.$router.history.current.query;
    //     let path = window.vm.$router.history.current.path;
    //     //对象的拷贝
    //     let newQuery = JSON.parse(JSON.stringify(query));
    //     newQuery[key] = [value];
    //     window.vm.$router.push({path, query: newQuery});
    // };


// 初始化BScroll
// import BScroll from 'better-scroll'
// u.BScroll = (id, options, callback) => {
//     options = Object.assign(options, {
//         probeType: 3,
//         scrollY: true, //滚动方向为 Y 轴
//         scrollX: true, // 滚动方向为 X 轴
//         momentum: true, //当快速滑动时是否开启滑动惯性
//         bounce: true, //是否启用回弹动画效果
//         bounceTime: 600, //回弹动画的动画时长。
//         HWCompositing: true, // 是否启用硬件加速
//         useTransition: true, // 是否使用CSS3的Transition属性
//         useTransform: true, // 是否使用CSS3的Transform属性
//         snapLoop: false, //是否可以无缝循环轮播
//         resizePolling: 60,
//         preventDefault: true,
//         click: true,  //这个很重要，，默认是会阻止浏览器的点击事件
//         pullUpLoad: {
//             threshold: -10
//         },
//     });
//     // console.log(id)
//     let bScroll = new BScroll(id, options);
//     bScroll.on('beforeScrollStart', () => {
//         let Home1refY = parseFloat(document.defaultView.getComputedStyle(document.getElementById(id).firstChild, null).transform.substring(7).split(',')[5]);
//         callback(!(Home1refY.toString() === "NaN" || Home1refY >= 0));
//     });

//     bScroll.on('scrollEnd', () => {
//         callback(false);
//     });
//     bScroll.on('pullingUp', () => {
//         bScroll.finishPullUp();
//         callback(false, "pullingUp");
//     });
//     bScroll.wrapper.addEventListener('touchstart', (e) => {
//         e.stopPropagation();
//     });

//     return bScroll;
// };

    window.$vcore = u;
})(window);




